<template>
  <div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-5" v-if="bookingOption">
      <div>

        <ValidationObserver ref="slotsFormRef" class="grid grid-cols-4 bg-white p-10 rounded-lg shadow">

          <div class="col-span-4 mt-3 mb-10 flex">
            <vs-checkbox v-model="is_time_slotted" @change="toggleIsTimeSlotted">{{ is_time_slotted ? $t('working_hours.deactivate_time_slots') : $t('working_hours.activate_time_slots') }}</vs-checkbox>
          </div>

          <div class="col-span-4" v-if="is_time_slotted">
            <span class="text-lg lg:text-xl">{{ $t('working_hours.booking_duration') }}:</span>
          </div>

          <div class="col-span-4 grid grid-cols-12 gap-5 mt-5" v-if="is_time_slotted">
            <div class="col-span-4 grid grid-cols-2 content-center">
              {{ $t('working_hours.duration') }}
            </div>
            <div class="col-span-7 grid grid-cols-2">
              <vs-input
                type="number"
                placeholder="60"
                v-model="duration"
                @input="checkChanges"
              />
              <vs-select
                v-model="duration_unit"
                @change="checkChanges"
                >
                <vs-select-item :key="index" :value="item.key" :text="$i18n.locale == 'ar' ? item.text_ar : item.text_en " v-for="item,index in [{text_ar: 'دقيقة', text_en: 'Minutes', key: 'min'}, {text_ar: 'ساعة', text_en: 'Hours', key: 'hour'}]" />
              </vs-select>
            </div>
          </div>

          <div class="col-span-4 grid grid-cols-12 gap-5 mt-5" v-if="is_time_slotted">
            <div class="col-span-4 grid grid-cols-2 content-center">
              {{ $t('working_hours.buffer') }}
            </div>
            <div class="col-span-7 grid grid-cols-2">
              <vs-input
                type="number"
                placeholder="60"
                v-model="buffer"
                @input="checkChanges"
              />
              <vs-select
                v-model="buffer_unit"
                @change="checkChanges"
                >
                <vs-select-item :key="index" :value="item.key" :text="$i18n.locale == 'ar' ? item.text_ar : item.text_en " v-for="item,index in [{text_ar: 'دقيقة', text_en: 'Minutes', key: 'min'}, {text_ar: 'ساعة', text_en: 'Hours', key: 'hour'}]" />
              </vs-select>
            </div>
          </div>

          <div class="col-span-4 mt-5" v-if="is_time_slotted">
            <span class="text-lg lg:text-xl">{{ $t('working_hours.available_days') }}:</span>
          </div>

          <div class="col-span-4 grid grid-cols-12 gap-5 my-5" v-for="(working_hour, index) in working_hours" :key="index" v-if="is_time_slotted">
            <div class="col-span-4 grid grid-cols-2 content-center">
              <vs-checkbox
                v-model="working_hours[index].is_enabled"
                @change="checkChanges"
              ></vs-checkbox>
              <span :class="{'line-through': !working_hour.is_enabled}" class="text-lg">
                {{ $i18n.locale == 'ar' ? working_hour.day_ar : working_hour.day }}
              </span>
            </div>
            <div class="col-span-7">
              <div v-if="working_hour.is_enabled">
                <div v-for="(wh, inIndex) in working_hour.working_hours" :key="inIndex" >
                  <div class="flex space-x-2 mt-2">
                    <vs-input
                      placeholder="Ex: 8:00"
                      v-model="wh.start_time"
                      v-mask="'##:##'"
                      @input="validateWorkingTimes(index)"
                      val-icon-danger="error" :danger="working_hour.error != null"
                    />
                    <vs-input
                      placeholder="Ex: 23:00"
                      v-model="wh.end_time"
                      v-mask="'##:##'"
                      @input="validateWorkingTimes(index)"
                      val-icon-danger="error" :danger="working_hour.error != null"
                    />
                    <vs-button color="danger" type="border" icon="close" @click="removeWorkingHour(index, inIndex)"></vs-button>
                  </div>
                </div>
                <div v-if="working_hour.error" class="mt-4">
                  <span class="text-sm text-danger">
                    {{ $i18n.locale == 'ar' ? working_hour.error.message_ar : working_hour.error.message_en }}
                  </span>
                </div>
              </div>
              <div v-else>
                {{ $t('working_hours.inactive') }}
              </div>
            </div>
            <div class="col-span-1 flex flex-col justify-end" v-if="working_hour.is_enabled">
              <vs-button color="success" type="border" icon="add" @click="addWorkingHour(index)"></vs-button>
            </div>
          </div>
          <div class="col-span-4 flex justify-end mt-5" v-if="is_time_slotted">
            <vs-button color="success" icon="check" @click="save">{{ $t('working_hours.save') }}</vs-button>
          </div>

          <div class="col-span-4 my-10 flex justify-center" v-if="!is_time_slotted">
            <span class="text-xl text-center">
              {{ $t('working_hours.activate_time_slotts_please') }}
            </span>
          </div>
        </ValidationObserver>


      </div>
      <div v-if="is_time_slotted">
        <div class="grid grid-cols-2 gap-3">
          <div class="col-span-1">
            <div>
              <datepicker :inline="true" format="dd-MM-yyyy" :disabled-dates="disabledDates" :language="$vs.rtl ? langAr : langEn" v-model="previewDate" @selected="dateSelected"></datepicker>
            </div>
            <div v-if="has_any_changes" class="my-5">
              <span>{{ $t('working_hours.please_save_to_see_changes') }}</span>
            </div>
          </div>
          <div class="col-span-1 self-start">
            <div v-for="(slot, index) in preview_time_slots" :key="index">
              <div class="w-1/2">
                <div class="bg-white p-5 rounded-lg shadow my-2 mx-2">
                  <span clas="">{{ slot.start_time }}</span>
                  ->
                  <span clas="">{{ slot.end_time }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { en, ar } from 'vuejs-datepicker/src/locale'
import Datepicker from 'vuejs-datepicker'
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Datepicker,
  },
  props: {},
  data () {
      return {
        working_hours: [],
        duration: null,
        duration_unit: null,
        buffer: null,
        buffer_unit: null,
        is_time_slotted: null,

        langAr: ar,
        langEn: en,
        previewDate: new Date(), // Set default date to today
        disabledDates: {
          to: new Date(new Date().setDate(new Date().getDate() - 1)), // Disable all dates until yesterday
        },

        has_any_changes: false,
      }
  },
  computed: {
    bookingOption () {
      return this.$store.state.bookingOptions.currentBookingOption;
    },
    preview_time_slots() {
      return this.$store.state.bookingOptions.preview_time_slots;
    }
  },
  created() {

  },
  methods: {
    addWorkingHour(index) {
      const newWorkingHour = { start_time: null, end_time: null };
      this.working_hours[index].working_hours.push(newWorkingHour);
    },
    removeWorkingHour(outerIndex, innerIndex) {
      this.working_hours[outerIndex].working_hours.splice(innerIndex, 1)
      this.checkChanges();
    },
    validateWorkingTimes(index) {
      const workingHours = this.working_hours[index].working_hours;
      let error = null; // Store a single error object

      // Convert time strings to minutes for easier comparison
      function timeToMinutes(time) {
        const timeFormatRegex = /^([01]?\d|2[0-3]):[0-5]\d$/;

        if (!timeFormatRegex.test(time)) {
          return 0; // Invalid format
        }
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
      }

      // Validation rules
      const validationRules = [
          {
              rule: (slot) => timeToMinutes(slot.start_time) < timeToMinutes(slot.end_time),
              message_en: "Start time must be earlier than end time.",
              message_ar: "يجب أن يكون وقت البداية قبل وقت النهاية."
          },
          {
              rule: (slot, allSlots, slotIndex) =>
                  !allSlots.some(
                      (otherSlot, otherIndex) =>
                          otherIndex !== slotIndex &&
                          timeToMinutes(slot.start_time) < timeToMinutes(otherSlot.end_time) &&
                          timeToMinutes(slot.end_time) > timeToMinutes(otherSlot.start_time)
                  ),
              message_en: "Time slot overlaps with another.",
              message_ar: "الوقت يتداخل مع فترة أخرى."
          }
      ];

      // Apply validations
      for (let slotIndex = 0; slotIndex < workingHours.length; slotIndex++) {
          const slot = workingHours[slotIndex];

          // Step 1: Validate time format for both start_time and end_time
          if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(slot.start_time)) {
              this.working_hours[index].error = {
                  message_en: `Invalid start time format. Please use HH:MM.`,
                  message_ar: `صياغة وقت البداية غير صحيحة`
              };
              return; // Stop validation for this slot
          }

          if (!/^([01]\d|2[0-3]):([0-5]\d)$/.test(slot.end_time)) {
              this.working_hours[index].error = {
                  message_en: `Invalid end time format. Please use HH:MM.`,
                  message_ar: `صياغة وقت النهاية غير صحيحة`
              };
              return; // Stop validation for this slot
          }

          // Step 2: Apply other validation rules
          for (const { rule, message_en, message_ar } of validationRules) {
              if (!rule(slot, workingHours, slotIndex)) {
                  // If validation fails, set error object and break
                  this.working_hours[index].error = { message_en, message_ar };
                  return; // Stop validation for this slot
              }
          }
      }

      this.working_hours[index].error = null;

      this.checkChanges();
    },
    checkChanges() {
      console.log('checking the changes of the data...')
      console.log(this.working_hours)

      const res = this.deepCompare(this.working_hours, this.bookingOption.working_hours);

      if(!res) {
        this.has_any_changes = true;
        return;
      } else this.has_any_changes = false;


      if(this.duration != this.bookingOption.duration) {
        this.has_any_changes = true;
        return;
      } else this.has_any_changes = false;

      if(this.duration_unit != this.bookingOption.duration_unit) {
        this.has_any_changes = true;
        return;
      } else this.has_any_changes = false;

      if(this.buffer != this.bookingOption.buffer) {
        this.has_any_changes = true;
        return;
      } else this.has_any_changes = false;

      if(this.buffer_unit != this.bookingOption.buffer_unit) {
        this.has_any_changes = true;
        return;
      } else this.has_any_changes = false;


    },
    deepCompare(obj1, obj2) {
      if (typeof obj1 !== typeof obj2) return false;

      if (typeof obj1 === 'object' && obj1 !== null) {
        if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        return keys1.every(key => this.deepCompare(obj1[key], obj2[key]));
      }

      return obj1 === obj2;
    },
    async save() {

      let data = {
        duration: this.duration,
        duration_unit: this.duration_unit,
        buffer: this.buffer,
        buffer_unit: this.buffer_unit,
        working_hours: JSON.parse(JSON.stringify(this.working_hours))
      }

      let flagged = false;

      for (let index = 0; index < data.working_hours.length; index++) {
        const wh = data.working_hours[index];
        if (wh.is_enabled && wh.error) {
          flagged = true;
          break;
        }

        if (!wh.is_enabled && wh.error) {
          data.working_hours[index].working_hours = [{ start_time: '09:00', end_time: '09:01' }]
        }
        if(wh.working_hours && wh.working_hours.length == 0) {
          data.working_hours[index].working_hours = [{ start_time: '09:00', end_time: '09:01' }]
        }

      }

      if(flagged) return;

      const response = await this.$store.dispatch('bookingOptions/setBookingOptionTimeSlots', {venueId: this.bookingOption.venue_id, optionId: this.bookingOption.id, data})

      console.log(response);
      if(response.status == 200) {
        this.$vs.notify({
            color:'success',
            text: response.data.message
        })
        this.dateSelected(this.previewDate)
      }

    },
    async toggleIsTimeSlotted() {
      await this.$store.dispatch('bookingOptions/toggleIsTimeSlotted', {venueId: this.bookingOption.venue_id, optionId: this.bookingOption.id})
    },
    async dateSelected($event) {
      const date = new Date($event);
      const formattedDate = date.toISOString().split("T")[0];

      const response = await this.$store.dispatch('bookingOptions/getPreviewTimeSlots', {venueId: this.bookingOption.venue_id, optionId: this.bookingOption.id, date: formattedDate})

      console.log(response);
    }
  },
  watch: {
    bookingOption: function(newVal) {
      this.working_hours = JSON.parse(JSON.stringify(newVal.working_hours));
      this.duration = newVal.duration
      this.duration_unit = newVal.duration_unit
      this.buffer = newVal.buffer
      this.buffer_unit = newVal.buffer_unit
      this.is_time_slotted = newVal.is_time_slotted
    }
  },
}
</script>

<style>
.full-width-datepicker .vdp-datepicker {
  width: 100%; /* Make the datepicker span the full width of the parent */
}
</style>

