<template>
  <div id="scaffold">
    <WorkingHoursForm />
  </div>
</template>

<script>

import WorkingHoursForm from '@/views/components/qosoor-components/optionWorkingHours/OptionWorkingHoursForm.vue'
export default {
  data () {
    return {}
  },
  components: {
    WorkingHoursForm
  },
  created () {
    this.$store.dispatch('loader/loaderOff')
    this.getBookingOption()
  },
  methods: {
    async getBookingOption () {
      this.$store.dispatch('bookingOptions/getBookingOption', {venueId: this.$route.params.id, optionId: this.$route.params.option_id})
    }
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#scaffold {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}

</style>
